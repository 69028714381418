<template>
  <v-card>
    <vue-snotify />
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Confirmation',
  data: () => ({
  }),
  mounted () {
    this.confirm()
  },
  methods: {
    ...mapActions(['confirmEmail']),
    async confirm () {
      try {
        if (this.$route.params.confirmation === 'email-confirmation' && this.$route.params.token) {
          const { data } = await this.confirmEmail(this.$route.params.token)
          if (!data || !data.success) return
          this.$snotify.notifications = []
          this.$snotify.success('Email address was confirmed succesfully')
          setTimeout(() => {
            this.$router.push('/login')
          }, 4000)
        }
        if (this.$route.params.confirmation === 'recover' && this.$route.params.token && this.$route.params.uuid) {
          this.$router.push({ name: 'Recovery', params: { uuid: this.$route.params.uuid, token: this.$route.params.token } })
        }
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        setTimeout(() => {
          this.$router.push('/login')
        }, 2000)
      }
    }
  }
}
</script>
